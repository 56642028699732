import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'

const Volumes = () => {
  const userId = window.sessionStorage.getItem("id")
  const [volumes, setVolumes] = useState([])
  const [name, setName] = useState("")
  const [year, setYear] = useState("")
  const [id, setId] = useState("")
  const [savingLoader, setSavingLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState("")

  const getVolume = async () => {
    try {
      const res = await fetch(window.api + "getAllVolumeAdmin")
      const data = await res.json()
      setVolumes(data.volumeList)

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getVolume()
  }, []);

  function setEditVolume(id, name, year) {
    setId(id)
    setName(name)
    setYear(year)
  }

  function unSetEditVolume() {
    setId("")
    setName("")
    setYear("")
  }

  async function save() {
    setSavingLoader(true)
    const formData = new FormData()
    formData.append('id', id)
    formData.append('name', name)
    formData.append('year', year)
    formData.append('updated_by', userId)
    try {
      let res = await fetch(window.api + "saveVolume", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.already) {
        setSavingLoader(false)
        setSuccess(false)
        setSuccessText("")
        setError(true)
        setErrorText("Speciality already added")
      } else if (res.success) {
        getVolume()
        setSavingLoader(false)
        setId("")
        setName("")
        setYear("")
        setError(false)
        setErrorText("")
        setSuccess(true)
        setSuccessText("Speciality saved successfully")
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteVolume(id) {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to Delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => del(id)
        },
        {
          label: 'No',

        }
      ]
    })

  }

  async function del(id) {
    setLoader(true)
    const formData = new FormData()
    formData.append('id', id)

    try {
      let res = await fetch(window.api + "deleteVolume", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.success) {
        getVolume()
        setLoader(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
      <div className='row'>
        <div className='col-md-5'>
          {error ?
            <>
              <div class="alert alert-danger">
                {errorText}
              </div>
            </> :
            success ?
              <>
                <div class="alert alert-success">
                  {successText}
                </div>
              </> :
              <></>
          }
          <div className='form-group'>
            <label>Name</label>
            <input type='text' placeholder='e.g. Vol.1' className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className='form-group'>
            <label>Year</label>
            <input type='number' placeholder='e.g. 2022' className='form-control' value={year} onChange={(e) => setYear(e.target.value)} />
          </div>
          <div className='form-group'>
            <button onClick={save} className='btn btn-success' disabled={!name || !year}>{savingLoader ? <>Saving..</> : <>{id ? 'Update' : 'Save'} </>}</button>
            {id ?
              <>
                <button onClick={unSetEditVolume} className='btn btn-danger'>Cancel</button>
              </> :
              <></>}
          </div>
        </div>
        <div className='col-md-7'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Volume Name</th>
                <th>Year</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(volumes).length > 0 ? volumes.map(v => (
                (v.name === name && id == v.id) ?
                  <></> :
                  <tr>
                    <td>{v.name}</td>
                    <td>{v.Year}</td>
                    <td>
                      <i className='far fa-edit edit-icon-i' onClick={() => setEditVolume(v.id, v.name, v.Year)}></i>
                      <i className='far fa-trash-alt delete-icon-i' onClick={() => deleteVolume(v.id)}></i>
                    </td>
                  </tr>

              )) :
                <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Volumes