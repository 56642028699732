import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'

const Speciality = () => {
    const userId = window.sessionStorage.getItem("id")
    const [specialities, setSpecialities] = useState([])
    const [name, setName] = useState("")
    const [id, setId] = useState("")
    const [savingLoader, setSavingLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successText, setSuccessText] = useState("")
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")

    const getSpeciality = async () => {
        try {
            const res = await fetch(window.api + "getAllSpecAdmin")
            const data = await res.json()
            setSpecialities(data.specialityList)

        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        getSpeciality()
    }, []);

    function setEditSpeciality(id, name) {
        setId(id)
        setName(name)
    }

    function unSetEditSpeciality() {
        setId("")
        setName("")
    }

    async function save() {
        setSavingLoader(true)
        const formData = new FormData()
        formData.append('id', id)
        formData.append('name', name)
        formData.append('updated_by', userId)
        try {
            let res = await fetch(window.api + "saveSpeciality", {
                method: 'POST',
                body: formData,
            })

            res = await res.json()
            if (res.already) {
                setSavingLoader(false)
                setSuccess(false)
                setSuccessText("")
                setError(true)
                setErrorText("Speciality already added")
            } else if (res.success) {
                getSpeciality()
                setSavingLoader(false)
                setId("")
                setName("")
                setError(false)
                setErrorText("")
                setSuccess(true)
                setSuccessText("Speciality saved successfully")
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function deleteSpeciality(id) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => del(id)
                },
                {
                    label: 'No',

                }
            ]
        })

    }

    async function del(id) {
        setLoader(true)
        const formData = new FormData()
        formData.append('id', id)

        try {
            let res = await fetch(window.api + "deleteSpeciality", {
                method: 'POST',
                body: formData,
            })

            res = await res.json()
            if (res.success) {
                getSpeciality()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
            <div className='row'>
                <div className='col-md-5'>
                    {error ?
                        <>
                            <div class="alert alert-danger">
                                {errorText}
                            </div>
                        </> :
                        success ?
                            <>
                                <div class="alert alert-success">
                                    {successText}
                                </div>
                            </> :
                            <></>
                    }
                    <div className='form-group'>
                        <label>Name</label>
                        <input type='text' className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='form-group'>
                        <button onClick={save} className='btn btn-success' disabled={!name}>{savingLoader ? <>Saving..</> : <>{id ? 'Update' : 'Save'} </>}</button>
                        {id ?
                            <>
                                <button onClick={unSetEditSpeciality} className='btn btn-danger'>Cancel</button>
                            </> :
                            <></>}
                    </div>
                </div>
                <div className='col-md-7'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(specialities).length > 0 ? specialities.map(s => (
                                (s.name === name && id == s.id) ?
                                    <></> :
                                    <tr>
                                        <td>{s.name}</td>
                                        <td>
                                            <i className='far fa-edit edit-icon-i' onClick={() => setEditSpeciality(s.id, s.name)}></i>
                                            <i className='far fa-trash-alt delete-icon-i' onClick={() => deleteSpeciality(s.id)}></i>
                                        </td>
                                    </tr>
                            )) :
                                <></>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Speciality