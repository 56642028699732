import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'react-confirm-alert/src/react-confirm-alert.css'

const RetManu = () => {

    const [retManuListLoader, setRetManuListLoader] = useState(false)

    const [retManuscript, setRetManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [returnReplyFile, setReturnReplyFile] = useState("")
    const [returnManuComments, setReturnManuComments] = useState("")

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    const [commentShow, setCommentShow] = useState(false)
    const commentsModalClose = () => {
        setCommentShow(false)
        setReturnReplyFile("")
        setReturnManuComments("")
    }
    const commentsModalShow = (manuComments, manuFile) => {
        setReturnManuComments(manuComments)
        setReturnReplyFile(manuFile)
        setCommentShow(true)
    }

    async function manuscriptData() {
        try {
            setRetManuListLoader(true)
            let datares = await fetch(window.api + "getAllDmeRetManuAdmin")
            datares = await datares.json()
            if (datares) {
                setRetManuscript(datares.manuscripts)
                setRetManuListLoader(false)
            }

        } catch (err) {
            console.log(err);
        }
    }


    React.useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    return (
        <>
            <Modal show={authorShow} onHide={authorModalClose}>
                <Modal.Header>
                    <Modal.Title>Author Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <table className='table table-striped'>
                                <thead>
                                    <th>Name</th>
                                    <th>Affiliation</th>
                                </thead>
                                {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                    <tbody>
                                        <tr>
                                            <td>{ad.author_name}</td>
                                            <td>{ad.author_affiliation}</td>
                                        </tr>
                                    </tbody>
                                )) : <></>}
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={authorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={commentShow} size="xl" onHide={commentsModalClose}>
                <Modal.Header>
                    <Modal.Title>Comments For manuscriptAuthors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-12'>
                                    {(returnManuComments !== "") ?
                                        <>
                                            <p>
                                                <b>M.E Comments: </b>
                                                {returnManuComments}
                                            </p>
                                        </> :
                                        <></>}
                                    {(returnReplyFile !== "") ?
                                        <>
                                            <p>
                                                <b>M.E Reply File: </b>
                                                <a href={window.storage + "downloadReply/" + returnReplyFile} target="_blank" rel="noreferrer" download={returnReplyFile}>
                                                    <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i> Download Reply File
                                                </a>
                                            </p>
                                        </> :
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={commentsModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {(retManuListLoader) ?
                <><i>Fetching Record Please Wait....</i></> :
                <>
                    {Object.keys(retManuscript).length > 0 ?
                        <>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-bordered ret-manu">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title / Author</th>
                                            <th>File</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {retManuscript.map(m => (
                                            <tr key={m}>
                                                <td>
                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{m.manu_year + "-VSOHJ-" + m.manu_pno}</>}<br />
                                                    {m.manu_date.substr(0, 10)}
                                                </td>
                                                <td>
                                                    {m.manu_title}<br />
                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors)}><i className='fas fa-user'></i>
                                                        {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                            <> {a.author_name},</>
                                                        )) : <></>}
                                                    </span>
                                                </td>
                                                <td>
                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    <i className="icon-property-info" title='View Comments' onClick={() => commentsModalShow(m.manu_file_me_comnts, m.manu_file_me_rep)}>View</i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}

                </>}

        </>
    )
}

export default RetManu