import React, { useState, useEffect } from 'react'
import Loading from 'react-fullscreen-loading'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const Reviewers = () => {
    const [loader, setLoader] = useState(false)
    const [ReviewerList, setReviewerList] = useState([])
    const [title, setTitle] = useState([])
    const [special, setSpecial] = useState([])
    const [country, setCountry] = useState([])

    async function reviewerData() {
        setLoader(true)
        try {
            const formData = new FormData
            formData.append('list', 1)
            let result = await fetch(window.api + "getAllReviewers", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result) {
                setLoader(false)
                setReviewerList(result.Reviewers)
                setTitle(result.title)
                setCountry(result.countries)
                setSpecial(result.specialization)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        reviewerData()
    }, [])

    const terminateReviewer = async (userId) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Terminate Reviewer?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => terminateR(userId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const terminateR = async (userId) => {
        try {
            setLoader(true)
            const formData = new FormData
            formData.append('user_id', userId)
            let result = await fetch(window.api + "terminateReviewer", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result.success) {
                reviewerData()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }


    //get data from form
    const [inputTitle, setInputTitle] = useState("")
    const [inputCountry, setInputCountry] = useState("")
    const [inputfname, setInputFname] = useState("")
    const [inputlname, setInputLname] = useState("")
    const [inputspecial, setInputSpecial] = useState("")
    const [inputaffiliation, setInputAffiliation] = useState("")
    const [inputmobile, setInputMobile] = useState("")
    const [inputemail, setInputEmail] = useState("")
    const [inputpass, setInputPass] = useState("")
    const [errorText, setErrorText] = useState("")
    const [success, setSuccess] = useState(false)

    const register = async () => {
        setLoader(true)
        const formData = new FormData
        formData.append('fname', inputfname)
        formData.append('lname', inputlname)
        formData.append('title', inputTitle)
        formData.append('country', inputCountry)
        formData.append('specialization', inputspecial)
        formData.append('affiliation', inputaffiliation)
        formData.append('mobile', inputmobile)
        formData.append('email', inputemail)
        formData.append('password', inputpass)

        try {
            let userData = await fetch(window.api + "addReviewer", {
                method: 'POST',
                body: formData,
            })

            userData = await userData.json()
            if (userData.already) {
                setLoader(false)
                setSuccess(false)
                setErrorText(userData.already)
            } else {
                setLoader(false)
                setSuccess(true)
                setErrorText('')
                reviewerData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
            {success ?
                <>
                    <div class="alert alert-success">
                        Reviewer Added Successfully
                    </div>
                </> :
                <></>}
            {errorText ?
                <>
                    <div class="alert alert-danger">
                        {errorText}
                    </div>
                </> :
                <></>}
            <div className="row">

                <div className="col-md-4 p-1">
                    <select className="form-control" onChange={(e) => setInputCountry(e.target.value)} value={inputCountry}>
                        <option value="">Select Country</option>
                        {
                            country.map((e) => {
                                return (
                                    <option value={e.id} key={e.id}   >{e.country_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2 p-1">
                    <select className="form-control" onChange={(e) => setInputTitle(e.target.value)} value={inputTitle}>
                        <option value="">Select title</option>
                        {
                            title.map((e) => {
                                return (
                                    <option value={e.id} key={e.id}>{e.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-3 p-1">
                    <input className="form-control" type="text" placeholder="First Name " id="fname" onChange={(e) => setInputFname(e.target.value)} value={inputfname} />

                </div>
                <div className="col-md-3 p-1">
                    <input className="form-control" type="text" placeholder="Last Name " id="lname" onChange={(e) => setInputLname(e.target.value)} value={inputlname} />

                </div>
                <div className="col-md-4 p-1">
                    <select name="" id="special" className="form-control" onChange={(e) => setInputSpecial(e.target.value)} value={inputspecial}>
                        <option value="">Select Specialization</option>
                        {
                            special.map((e) => {
                                return (
                                    <option value={e.id} key={e.id} >{e.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-8 p-1">
                    <input className="form-control" type="text" placeholder="your affiliation" id="affiliation" onChange={(e) => setInputAffiliation(e.target.value)} value={inputaffiliation} />

                </div>
                <div className="col-md-4 p-1">
                    <input className="form-control" type="number" placeholder="Mobile Number" id="phone" onChange={(e) => setInputMobile(e.target.value)} value={inputmobile} />

                </div>
                <div className="col-md-4 p-1">
                    <input className="form-control" type="email" placeholder="Email Address" id="email"
                        onChange={(e) => setInputEmail(e.target.value)} value={inputemail} />

                </div>
                <div className="col-md-4 p-1">
                    <input className="form-control" type="password" placeholder="Your Password" id="pass"
                        onChange={(e) => setInputPass(e.target.value)} value={inputpass} />

                </div>

            </div>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <button
                        type="button"
                        className="btn btn-success"
                        disabled={
                            !inputTitle || !inputfname || !inputspecial || !inputemail || !inputpass
                            || !inputCountry || !inputlname || !inputaffiliation || !inputmobile
                            || !inputpass
                        }
                        onClick={register}>
                        Save
                    </button>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-12'>
                    <div className="table-responsive">
                        {Object.keys(ReviewerList).length > 0 ?
                            <>
                                <table className="table table-striped table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>Oric Id</th>
                                            <th>Name / Specialization</th>
                                            <th>Affiliation / Country</th>
                                            <th>Mobile / Email</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ReviewerList.map((u, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {(u.oric_id == null) ? 'None' : u.oric_id}
                                                </td>
                                                <td>
                                                    {u.title_name}. {u.fname} {u.lname}
                                                    {(u.rev_applied === 'true') ?
                                                        <i className="i-tag fas fa-bookmark text-success pl-1" title='Applied for Reviewer'></i> :
                                                        <></>}
                                                    <br />
                                                    {u.spec_name}
                                                </td>
                                                <td>
                                                    {u.affiliation}<br />
                                                    {u.country_name}
                                                </td>
                                                <td>
                                                    {u.mobile}<br />
                                                    {u.email}
                                                </td>
                                                <td>
                                                    {(u.reviewer == 1) ?
                                                        <>
                                                            <span>Reviewer</span> <i onClick={() => terminateReviewer(u.id)} className='fas fa-ban i-tag' title='Terminate'></i><br />
                                                        </> :
                                                        <></>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </> :
                            <>
                                <center>No Data</center>
                            </>}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Reviewers