import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const Login = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")

    async function Login() {
        setLoader(true)
        const formData = new FormData
        formData.append('email', email)
        formData.append('password', password)

        let result = await fetch(window.api + "adminLogin", {
            method: 'POST',
            body: formData
        })

        result = await result.json()

        if (result.errorEmail) {
            setLoader(false)
            setError(true)
            setErrorText("Email not found")

        } else if (result.errorPassword) {
            setLoader(false)
            setError(true)
            setErrorText("Password doesn't match")
        } else {
            setLoader(false)
            setError(false)
            window.sessionStorage.setItem("id", result.id)
            window.sessionStorage.setItem("name", result.full_name)
            window.sessionStorage.setItem("email", result.email)
            window.sessionStorage.setItem("type", result.type)
            navigate("/")
        }
    }

    return (
        <>
            <div id="app">
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Login</h4>
                                        {error ?
                                            <>
                                                <div className="alert alert-danger" role="alert">{errorText}</div>
                                            </> :
                                            <></>}
                                    </div>
                                    <div className="card-body">
                                        <form method="POST" className="needs-validation" novalidate="">
                                            <div className="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" tabindex="1" required autofocus />
                                                <div className="invalid-feedback">
                                                    Please fill in your email
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="d-block">
                                                    <label for="password" className="control-label">Password</label>
                                                    {/* <div className="float-right">
                                                        <a href="auth-forgot-password.html" className="text-small">
                                                            Forgot Password?
                                                        </a>
                                                    </div> */}
                                                </div>
                                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" tabindex="2" required />
                                                <div className="invalid-feedback">
                                                    please fill in your password
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" tabindex="3" />
                                                    <label className="custom-control-label" for="remember-me">Remember Me</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" onClick={Login} disabled={!email || !password} className="btn btn-primary btn-lg btn-block" tabindex="4">
                                                    {loader ? 'Signing...' : 'Signin to Admin Dashboard'}
                                                </button>
                                                <a href='https://az1-ss110.a2hosting.com:2096/cpsess7975064660/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX' className='btn btn-success btn-lg btn-block' tabindex="4" target='_blank'>Signin to Admin Email</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
        </>
    )
}

export default Login