import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Badge from 'react-bootstrap/Badge'
import Loading from 'react-fullscreen-loading'

const PublishPaper = () => {

    const userId = window.sessionStorage.getItem("id")

    const [loader, setLoader] = useState(false)
    const [paperFileLoader, setPaperFileLoader] = useState(false)
    const [paymentSlipLoader, setPaymentSlipLoader] = useState(false)
    const [publishLoader, setPublishLoader] = useState(false)

    const [success, setSuccess] = useState(false)

    const [volumeList, setVolumeList] = useState([])
    const [issueList, setIssueList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [manuList, setManuList] = useState([])
    const [paperList, setPaperList] = useState([])

    const [volume, setVolume] = useState("")
    const [issue, setIssue] = useState("")
    const [type, setType] = useState("")
    const [manuscript, setManuscript] = useState(0)
    const [doi, setDoi] = useState("")
    const [title, setTitle] = useState("")
    const [abstract, setAbstract] = useState("")
    const [authors, setAuthors] = useState("")
    const [keywords, setKeywords] = useState("")
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [year, setYear] = useState(0)
    const [pNo, setPNo] = useState(0)

    const [paperFile, setPaperFile] = useState("")
    const [paperFilePath, setPaperFilePath] = useState("")

    const [paymentSlip, setPaymentSlip] = useState("")
    const [paymentSlipPath, setPaymentSlipPath] = useState("")

    async function publicationData() {
        try {
            setLoader(true)
            let datares = await fetch(window.api + "getAllListAdmin")
            datares = await datares.json()
            if (datares) {
                setVolumeList(datares.volumes)
                setTypeList(datares.types)
                setManuList(datares.manuscripts)
                setLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        publicationData()
    }, [])

    async function getIssueList(volume) {
        setVolume(volume)
        try {
            setLoader(true)
            let datares = await fetch(window.api + "getIssues/" + volume)
            datares = await datares.json()
            if (datares) {
                setIssueList(datares.getIssues)
                setLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getPaperList(issue) {
        setIssue(issue)
        setLoader(true)
        const formData = new FormData()
        formData.append('vol_id', volume)
        formData.append('issue_id', issue)
        try {
            let dataRes = await fetch(window.api + "getPaperListAdmin", {
                method: 'POST',
                body: formData,
            })

            dataRes = await dataRes.json()
            if (dataRes) {
                setPaperList(dataRes.papers)
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function getManuscript(manuscript) {
        setManuscript(manuscript)
        setLoader(true)
        const formData = new FormData()
        formData.append('manu_id', manuscript)
        try {
            let sendRes = await fetch(window.api + "getManuDataAdmin", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes) {
                setTitle(sendRes.manuscript.manu_title)
                setAbstract(sendRes.manuscript.abstract)
                setAuthors(sendRes.authors)
                setUserName(sendRes.manuscript.user_name)
                setUserEmail(sendRes.manuscript.user_email)
                setYear(sendRes.manuscript.user_email)
                setPNo(sendRes.manuscript.user_email)
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function handlePaperFile(file) {
        setPaperFile(file[0])
        setPaperFileLoader(true)
        const formData = new FormData()
        formData.append('paperFile', file[0])
        try {
            let sendRes = await fetch(window.api + "uploadPaperFile", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.path) {
                setPaperFilePath(sendRes.path)
                setPaperFileLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function clearPaperFile() {
        setPaperFile("")
        setPaperFilePath("")
    }

    async function handlePaymentSlip(file) {
        setPaymentSlip(file[0])
        setPaymentSlipLoader(true)
        const formData = new FormData()
        formData.append('slipFile', file[0])
        try {
            let sendRes = await fetch(window.api + "uploadPaymentSlipFile", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.path) {
                setPaymentSlipPath(sendRes.path)
                setPaymentSlipLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function clearPaymentSlip() {
        setPaymentSlip("")
        setPaymentSlipPath("")
    }

    async function publishPaper() {
        setPublishLoader(true)
        const formData = new FormData()
        formData.append('volume_id', volume)
        formData.append('issue_id', issue)
        formData.append('type_id', type)
        formData.append('sub_id', 1)
        formData.append('availability', 1)
        formData.append('manu_id', manuscript)
        formData.append('doi', doi)
        formData.append('title', title)
        formData.append('abstract', abstract)
        formData.append('authors', authors)
        formData.append('keywords', keywords)
        formData.append('user_name', userName)
        formData.append('user_email', userEmail)
        formData.append('manu_year', year)
        formData.append('manu_pno', pNo)
        formData.append('paper_file', paperFilePath)
        formData.append('payment_slip', paymentSlipPath)
        formData.append('updated_by', userId)

        try {
            let sendRes = await fetch(window.api + "publishPaper", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                publicationData(); setVolume(""); setIssue(""); setType(""); setManuscript(""); setDoi("");
                setTitle(""); setAbstract(""); setAuthors(""); setKeywords(""); setUserName(""); setUserEmail("");
                setYear(""); setPNo(""); setPaperFile(""); setPaperFilePath(""); setPaymentSlip("");
                setPaymentSlipPath(""); setPublishLoader(false); setSuccess(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <center>
                                                <h4>Publish Paper</h4>
                                            </center>
                                        </div>
                                        <div className="card-body">
                                            {(success) ?
                                                <>
                                                    <div class="alert alert-success alert-dismissible show fade">
                                                        <div class="alert-body">
                                                            <button class="close" data-dismiss="alert">
                                                                <span>&times;</span>
                                                            </button>
                                                            This is a success alert.
                                                        </div>
                                                    </div>
                                                </> :
                                                <></>}
                                            <div className='row'>
                                                <div className='col-md-2 p-1'>
                                                    <select className='form-control' value={volume} onChange={(e) => getIssueList(e.target.value)}>
                                                        <option value="">--Volume--</option>
                                                        {Object.keys(volumeList).length > 0 ? volumeList.map(v => (
                                                            <option value={v.id}>{v.name} ({v.Year})</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className='col-md-2 p-1'>
                                                    <select className='form-control' value={issue} onChange={(e) => getPaperList(e.target.value)}>
                                                        <option value="">--Issue--</option>
                                                        {Object.keys(issueList).length > 0 ? issueList.map(i => (
                                                            <option value={i.id}>{i.name}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className='col-md-2 p-1'>
                                                    <select className='form-control' value={type} onChange={(e) => setType(e.target.value)}>
                                                        <option value="">--Type--</option>
                                                        {Object.keys(typeList).length > 0 ? typeList.map(t => (
                                                            <option value={t.id}>{t.name}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className='col-md-2 p-1'>
                                                    <select className='form-control' value={manuscript} onChange={(e) => getManuscript(e.target.value)}>
                                                        <option value="">--Manuscript--</option>
                                                        {Object.keys(manuList).length > 0 ? manuList.map(m => (
                                                            <option value={m.id}>{m.year}-VSOHJ-{m.pno}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className='col-md-4 p-1'>
                                                    <input type="text" className='form-control' value={doi} onChange={(e) => setDoi(e.target.value)} placeholder="Enter Doi" />
                                                </div>
                                                <div className='col-md-12 p-1'>
                                                    <input type="text" className='form-control form-control-sm' value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter Title" />
                                                </div>
                                                <div className='col-md-12 p-1'>
                                                    <textarea type="text" className='form-control form-control-sm' value={abstract} onChange={(e) => setAbstract(e.target.value)} placeholder="Enter Abstract" />
                                                </div>
                                                <div className='col-md-6 p-1'>
                                                    <input type="text" className='form-control form-control-sm' value={authors} onChange={(e) => setAuthors(e.target.value)} placeholder="Enter Authors" />
                                                </div>
                                                <div className='col-md-6 p-1'>
                                                    <input type="text" className='form-control form-control-sm' value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder="Enter Keywords" />
                                                </div>
                                                <div className='col-md-3 p-1'>
                                                    <label>Paper File</label>
                                                    {paperFileLoader ?
                                                        <>
                                                            <p><i style={{ color: 'red' }}>Uploading...</i></p>
                                                        </> :
                                                        <>
                                                            {paperFilePath ?
                                                                <>
                                                                    <p>
                                                                        <Badge bg="success" style={{ color: 'white' }}>File Uploaded</Badge> <i onClick={clearPaperFile} className="fas fa-times"></i>
                                                                    </p>
                                                                </> :
                                                                <>
                                                                    <input
                                                                        className="form-control"
                                                                        type="file"
                                                                        onChange={e => handlePaperFile(e.target.files)}
                                                                    />
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className='col-md-3 p-1'>
                                                    <label>Payment Slip</label>
                                                    {paymentSlipLoader ?
                                                        <>
                                                            <p><i style={{ color: 'red' }}>Uploading...</i></p>
                                                        </> :
                                                        <>
                                                            {paymentSlipPath ?
                                                                <>
                                                                    <p>
                                                                        <Badge bg="success" style={{ color: 'white' }}> File Uploaded</Badge> <i onClick={clearPaymentSlip} className="fas fa-times"></i>
                                                                    </p>
                                                                </> :
                                                                <>
                                                                    <input
                                                                        className="form-control"
                                                                        type="file"
                                                                        onChange={e => handlePaymentSlip(e.target.files)}
                                                                    />
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className='col-md-3 p-1'>
                                                    <button
                                                        className='btn btn-success'
                                                        style={{ marginTop: '30px' }}
                                                        disabled={!volume || !issue || !type || !doi || !title || !abstract || !authors || !keywords
                                                            || !paperFilePath
                                                        }
                                                        onClick={publishPaper}
                                                    >{publishLoader ? 'Publishing...' : 'Publish'}</button>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    {Object.keys(paperList).length > 0 ?
                                                        <>
                                                            <div className="table-responsive">
                                                                <table className="table table-sm table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Files</th>
                                                                            <th>Title / Author</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {paperList.map(m => (
                                                                            <tr key={m}>
                                                                                <td>
                                                                                    {(m.file != null) ?
                                                                                        <a href={window.storage + "downloadPaper/" + m.file} rel="noreferrer" target="_blank" download>
                                                                                            <i className='fas fa-file-download'></i> File
                                                                                        </a> :
                                                                                        <i>No File</i>}<br />
                                                                                    {(m.payment_slip != null) ?
                                                                                        <a href={window.storage + "downloadPaymentSlip/" + m.payment_slip} rel="noreferrer" target="_blank" download>
                                                                                            <i className='fas fa-file-download'></i> Slip
                                                                                        </a> :
                                                                                        <a></a>}
                                                                                </td>
                                                                                <td>
                                                                                    {m.title}<br />
                                                                                    <i className='fas fa-user'></i> {m.authors}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <div className='text-center'>
                                                                <i className='text-danger'>No Data</i>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default PublishPaper