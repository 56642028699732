import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Badge from 'react-bootstrap/Badge'
import Loading from 'react-fullscreen-loading'
import Table from 'react-bootstrap/Table'
import Collapse from 'react-bootstrap/Collapse'

const InProManu = () => {
    const userId = window.sessionStorage.getItem("id")

    const [manuListLoader, setManuListLoader] = useState(false)

    const [manuscript, setManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [specialityList, setSpecialityList] = useState([])
    const [revList, setRevList] = useState([])


    const [loader, setLoader] = useState(false)

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    async function manuscriptData() {
        try {
            setManuListLoader(true)
            let datares = await fetch(window.api + "getAllEicProManuAdmin")
            datares = await datares.json()
            if (datares) {
                setManuscript(datares.manuscripts)
                setManuListLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    const [open, setOpen] = useState(manuscript.map(() => false))
    useEffect(() => {
        if (manuscript.length !== open.length) {
            setOpen(manuscript.map(
                () => false))
        }
    }, [manuscript, open])

    const [revLoader, setRevLoader] = useState(false)
    const [revLoaderId, setRevLoaderId] = useState("")
    const [revSpeciality, setRevSpeciality] = useState("")
    const [revName, setRevName] = useState("")
    const [revManuAssignId, setRevManuAssignId] = useState("")
    const [revManuId, setRevManuId] = useState("")
    const [revManuTitle, setRevManuTitle] = useState("")
    const [revManuFileId, setRevManuFileId] = useState("")
    const [revManuYear, setRevManuYear] = useState("")
    const [revManuPno, setRevManuPno] = useState("")

    const [revShow, setRevShow] = useState(false)
    const revModalClose = () => {
        setRevShow(false)
        setRevManuAssignId("")
        setRevManuId("")
        setRevManuTitle("")
        setRevManuYear("")
        setRevManuPno("")
        setRevManuFileId("")
        setRevSpeciality("")
        setRevList([])
        setRevLoader()
        setRevLoaderId("")
        setRevName("")
        manuscriptData()
    }
    const revModalShow = async (manuId, manuTitle, manuYear, manuPno, manuFileId) => {
        setRevShow(true)
        // setRevManuAssignId(manuAssignId)
        setRevManuId(manuId)
        setRevManuTitle(manuTitle)
        setRevManuYear(manuYear)
        setRevManuPno(manuPno)
        setRevManuFileId(manuFileId)
        let specRes = await fetch(window.api + "getSpeciality")
        specRes = await specRes.json()
        setSpecialityList(specRes.specialityList)
    }

    async function searchRevByName(name) {

        setRevList([])
        setRevLoader(true)
        setRevName(name)

        const formData = new FormData()
        formData.append('name', name)
        formData.append('spec_id', revSpeciality)
        formData.append('rev_manu_id', revManuId)
        formData.append('rev_manu_file_id', revManuFileId)
        try {
            let revRes = await fetch(window.api + "searchRevByName", {
                method: 'POST',
                body: formData,
            })

            revRes = await revRes.json()
            if (revRes) {
                setRevLoader(false)
                setRevList(revRes.revList)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function sendToRev(reviewerId, reviewerEmail, reviewerName) {

        setRevLoader(true)
        setRevLoaderId(reviewerId)
        const formData = new FormData()
        // formData.append('rev_manu_assign_id', revManuAssignId)
        formData.append('rev_manu_id', revManuId)
        formData.append('rev_manu_title', revManuTitle)
        formData.append('rev_manu_file_id', revManuFileId)
        formData.append('rev_manu_year', revManuYear)
        formData.append('rev_manu_pno', revManuPno)
        formData.append('rev_id', reviewerId)
        formData.append('rev_email', reviewerEmail)
        formData.append('rev_name', reviewerName)
        formData.append('updated_by', userId)

        try {
            let sendData = await fetch(window.api + "sendToRevByEic", {
                method: 'POST',
                body: formData,
            })

            sendData = await sendData.json()
            if (sendData.success) {
                setRevLoaderId("")
                setRevLoader(false)
                searchRevByName(revName)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [retAuthLoader, setRetAuthLoader] = useState(false)
    const [retAuthManuId, setRetAuthManuId] = useState("")
    const [retAuthManuFileId, setRetAuthManuFileId] = useState("")
    const [retAuthManuTitle, setRetAuthManuTitle] = useState("")
    const [retAuthManuYear, setRetAuthManuYear] = useState("")
    const [retAuthManuPno, setRetAuthManuPno] = useState("")
    const [retAuthName, setRetAuthName] = useState("")
    const [retAuthEmail, setRetAuthEmail] = useState("")

    const [retComtAuthor, setRetComtAuthor] = useState("")
    const [retAuthReplyFile, setRetAuthReplyFile] = useState("")
    const [retAuthReplyFilePath, setRetAuthReplyFilePath] = useState("")
    const [retAuthReplyFileLoader, setRetAuthReplyFileLoader] = useState(false)

    const [retAuthShow, setRetAuthShow] = useState(false)
    const retAuthModalClose = () => {
        setRetAuthShow(false)
        setRetAuthManuId("")
        setRetAuthManuFileId("")
        setRetAuthManuTitle("")
        setRetAuthManuYear("")
        setRetAuthManuPno("")
        setRetAuthName("")
        setRetAuthEmail("")
        setRetComtAuthor("")
        setRetAuthReplyFile("")
        setRetAuthReplyFilePath("")
        manuscriptData()
    }
    const retAuthModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno, authName, authEmail) => {
        setRetAuthShow(true)
        setRetAuthManuId(manuId)
        setRetAuthManuFileId(manuFileId)
        setRetAuthManuTitle(manuTitle)
        setRetAuthManuYear(manuYear)
        setRetAuthManuPno(manuPno)
        setRetAuthName(authName)
        setRetAuthEmail(authEmail)
    }

    const handleRetAuthReplyFile = async (file) => {
        setRetAuthReplyFile(file[0])
        setRetAuthReplyFileLoader(true)
        const formData = new FormData
        formData.append('replyFile', file[0])
        try {
            let replyUploadData = await fetch(window.api + "uploadSeRetReplyFile", {
                method: 'POST',
                body: formData,
            })
            replyUploadData = await replyUploadData.json()
            if (replyUploadData.path) {
                setRetAuthReplyFileLoader(false)
                setRetAuthReplyFilePath(replyUploadData.path)
            } else {
                setRetAuthReplyFileLoader(false)
                setRetAuthReplyFilePath("")
            }
        } catch (err) {
            console.log(err)
        }
    }
    const clearSeRetReplyFile = () => {
        setRetAuthReplyFile("")
        setRetAuthReplyFilePath("")
    }

    const returnToAuthorFromSe = async () => {

        setRetAuthLoader(true)
        const formData = new FormData
        formData.append('ret_auth_manu_id', retAuthManuId)
        formData.append('ret_auth_manu_file_id', retAuthManuFileId)
        formData.append('ret_auth_manu_title', retAuthManuTitle)
        formData.append('ret_auth_manu_year', retAuthManuYear)
        formData.append('ret_auth_manu_pno', retAuthManuPno)
        formData.append('ret_auth_se_comments', retComtAuthor)
        formData.append('ret_auth_se_reply_file', retAuthReplyFilePath)
        formData.append('ret_auth_name', retAuthName)
        formData.append('ret_auth_email', retAuthEmail)
        formData.append('updated_by', userId)

        try {
            let retData = await fetch(window.api + "retAuthorFromEic", {
                method: 'POST',
                body: formData,
            })
            retData = await retData.json()
            if (retData.success) {
                setRetAuthLoader(false)
                retAuthModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [fetchRepLoader, setFetchRepLoader] = useState(false)
    const [repManuId, setRepManuId] = useState("")
    const [repManuFileId, setRepManuFileId] = useState("")
    const [repManuTitle, setRepManuTitle] = useState("")
    const [repManuYear, setRepManuYear] = useState("")
    const [repManuPno, setRepManuPno] = useState("")
    const [repManuData, setRepManuData] = useState([])
    const [repFileData, setRepFileData] = useState("")
    const [repShow, setRepShow] = useState(false)
    const repModalClose = () => {
        setRepShow(false)
        setRepManuId("")
        setRepManuFileId("")
        setRepManuTitle("")
        setRepManuYear("")
        setRepManuPno("")
        setRepManuData([])
    }
    const repModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno) => {
        setFetchRepLoader(true)
        setRepShow(true)
        setRepManuId(manuId)
        setRepManuFileId(manuFileId)
        setRepManuTitle(manuTitle)
        setRepManuYear(manuYear)
        setRepManuPno(manuPno)

        const formData = new FormData
        formData.append('manu_id', manuId)
        formData.append('manu_file_id', manuFileId)
        let result = await fetch(window.api + "getAllReviewersReply", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result) {
            setFetchRepLoader(false)
            setRepManuData(result.all_reviewers_reply)
            setRepFileData(result.files_comment)
        }
    }

    async function acceptManuscript(manuId, manuFileId, manuTitle, manuYear, manuPno, manuUser, manuEmail) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Accept?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => accept(manuId, manuFileId, manuTitle, manuYear, manuPno, manuUser, manuEmail)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async function accept(manuId, manuFileId, manuTitle, manuYear, manuPno, manuUser, manuEmail) {

        setLoader(true)
        const formData = new FormData()
        formData.append('acc_manu_id', manuId)
        formData.append('acc_manu_user_name', manuUser)
        formData.append('acc_manu_user_email', manuEmail)
        formData.append('acc_manu_title', manuTitle)
        formData.append('acc_manu_file_id', manuFileId)
        formData.append('acc_manu_year', manuYear)
        formData.append('acc_manu_pno', manuPno)
        formData.append('updated_by', userId)
        try {
            let rejData = await fetch(window.api + "acceptManuscript", {
                method: 'POST',
                body: formData,
            })

            rejData = await rejData.json()
            if (rejData.success) {
                setLoader(false)
                manuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [fileLoader, setFileLoader] = useState(false)
    const [rejLoader, setRejLoader] = useState(false)
    const [rejManuId, setRejManuId] = useState("")
    const [rejManuFileId, setRejManuFileId] = useState("")
    const [rejManuUserName, setRejManuUserName] = useState("")
    const [rejManuUserEmail, setRejManuUserEmail] = useState("")
    const [rejManuTitle, setRejManuTitle] = useState("")
    const [rejManuFile, setRejManuFile] = useState("")
    const [rejManuFilePath, setRejManuFilePath] = useState("")
    const [rejManuComments, setRejManuComments] = useState("")
    const [rejManuYear, setRejManuYear] = useState("")
    const [rejManuPno, setRejManuPno] = useState("")

    const [rejShow, setRejShow] = useState(false)
    const rejModalClose = () => {
        setRejShow(false)
        setRejManuFile("")
        setRejManuFilePath("")
        setRejManuId("")
        setRejManuFileId("")
        setRejManuComments("")
        setRejManuYear("")
        setRejManuPno("")
        manuscriptData()
    }
    const rejModalShow = (manuId, manuFileId, manuTitle, manuYear, manuPno, manuUser, manuEmail) => {

        setRejManuId(manuId)
        setRejManuFileId(manuFileId)
        setRejManuUserName(manuUser)
        setRejManuUserEmail(manuEmail)
        setRejManuTitle(manuTitle)
        const comments = "Dear " + manuUser + "! I am very much thankful to you for considering ‘Veterinary Sciences and One Health Journal’ (VSOHJ) to publish your scientific and professional achievements. However, I am sorry to inform you that your manuscript ‘" + manuTitle + "’and manuscript No. VSOHJ-" + manuYear + "-" + manuPno + " has been considered ‘unsuitable’ for publication in VSOHJ, however this rejection is based on comparison with high quality manuscripts submitted  to VSOHJ, and doesn’t mean the rejection of your research findings. You can submit it in any other journal more related to your findings. Looking forward for your future submissions. Best Regards"
        setRejManuComments(comments)
        setRejManuYear(manuYear)
        setRejManuPno(manuPno)
        setRejShow(true)
    }

    const handleReplyFile = async (file) => {
        setRejManuFile(file[0])
        setFileLoader(true)
        const formData = new FormData()
        formData.append('replyFile', file[0])
        try {
            let replyUploadData = await fetch(window.api + "uploadReply", {
                method: 'POST',
                body: formData,
            })

            replyUploadData = await replyUploadData.json()
            console.warn(replyUploadData)
            if (replyUploadData.path) {
                setFileLoader(false)
                setRejManuFilePath(replyUploadData.path)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const clearReplyFile = () => {
        setRejManuFile("")
        setRejManuFilePath("")
    }

    async function rejToAuthor() {
        setRejLoader(true)
        const formData = new FormData()
        formData.append('rej_manu_id', rejManuId)
        formData.append('rej_manu_user_name', rejManuUserName)
        formData.append('rej_manu_user_email', rejManuUserEmail)
        formData.append('rej_manu_title', rejManuTitle)
        formData.append('rej_manu_file_id', rejManuFileId)
        formData.append('rej_manu_file_path', rejManuFilePath)
        formData.append('rej_manu_comments', rejManuComments)
        formData.append('rej_manu_year', rejManuYear)
        formData.append('rej_manu_pno', rejManuPno)
        formData.append('updated_by', userId)
        try {
            let rejData = await fetch(window.api + "rejToAuthorFromMe", {
                method: 'POST',
                body: formData,
            })

            rejData = await rejData.json()
            if (rejData.success) {
                setRejLoader(false)
                rejModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <Modal show={revShow} size="lg" onHide={revModalClose}>
                <Modal.Header>
                    <Modal.Title>Send Manuscript to Reviewers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4 p-1'>

                                    <select value={revSpeciality} onChange={(e) => setRevSpeciality(e.target.value)} className='form-control'>
                                        <option value="">--Select Speciality--</option>
                                        {Object.keys(specialityList).length > 0 ? specialityList.map((s, index) => (
                                            <option key={index} value={s.id}>{s.name}</option>
                                        )) : <></>}
                                    </select>

                                </div>
                                <div className='col-md-6 p-1'>
                                    <input type="text" value={revName} onChange={(e) => searchRevByName(e.target.value)} className='form-control' placeholder='Search Reviewers by name' />
                                </div>
                                <div className='col-md-2 p-1'>

                                </div>
                            </div>
                            <div className='row'>
                                <table className='table table-striped table-sm'>

                                    {Object.keys(revList).length > 0 ?
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Name / Email</th>
                                                    <th>Affiliation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {revList.map((s, index) =>
                                                    <tr key={index}>
                                                        <td>{s.fname} {s.lname}<br />{s.email}</td>
                                                        <td>{s.affiliation}</td>
                                                        <td>
                                                            {(s.assigned_manu == 0) ?
                                                                <button onClick={() => sendToRev(s.id, s.email, s.fname)} className='btn btn-success btn-sm' disabled={revLoader || revLoaderId}>
                                                                    {(revLoader && revLoaderId == s.id) ?
                                                                        'Sending..' : 'send'
                                                                    }
                                                                </button> :
                                                                <i>Assigned</i>}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </> :
                                        <><table className='table text-center text-danger'><tbody><tr><td>No Data</td></tr></tbody></table></>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={revModalClose} disabled={revLoader || revLoaderId}>
                        Cancle
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={retAuthShow} size="lg" onHide={retAuthModalClose}>
                <Modal.Header>
                    <Modal.Title>Return to Author from S.E</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label>Comments for Author</label>
                                <textarea className="form-control" rows="7" value={retComtAuthor} onChange={(e) => setRetComtAuthor(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className="form-group">
                                <label>Reply File</label>
                                {retAuthReplyFileLoader ?
                                    <>
                                        <p><i className="invalid-alert">Uploading....</i></p>
                                    </> :
                                    <>
                                        {retAuthReplyFilePath ?
                                            <>
                                                <p>
                                                    <Badge bg="success">Reply File Uploaded</Badge><i onClick={clearSeRetReplyFile} className="bi bi-x"></i>
                                                </p>
                                            </> :
                                            <>
                                                <input type="file" className="form-control" onChange={e => handleRetAuthReplyFile(e.target.files)} />
                                            </>}
                                    </>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={returnToAuthorFromSe} disabled={retAuthLoader || !retComtAuthor}>
                        {retAuthLoader ? 'Returning...' : 'Return'}
                    </Button>
                    <Button variant="danger" onClick={retAuthModalClose} disabled={retAuthLoader}>
                        Cancle
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={repShow} size="xl" onHide={repModalClose}>
                <Modal.Header>
                    <Modal.Title><i className="fas fa-times icon-property-report-close" onClick={repModalClose}></i> Reviewers Report for Manuscript</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable={true}>
                    <div className="scrollbar" id="style-1">
                        <div className="container-fluid">

                            {(fetchRepLoader) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <i className="text-info">Fetching Record Please Wait..</i>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="col-md-2 col-2">
                                            {/* <Image
                                                src='/logo.png'
                                                width={150}
                                                height={60}
                                                objectFit="contain"
                                                alt='logo'
                                            /> */}
                                        </div>
                                        <div className="col-md-8 col-8 report-heading">
                                            <h2>Veterinary Sciences and One Health Journal</h2>
                                        </div>
                                        <div className="col-md-2 col-2">
                                            <h5>VSOHJ-{repManuYear}-{repManuPno}</h5>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <center>
                                                <h4>{repManuTitle}</h4>
                                            </center>
                                        </div>
                                    </div>
                                    <Table bordered size="sm">
                                        <tbody>
                                            {(repFileData.me_comments != null) ?
                                                <>
                                                    <tr>
                                                        <td style={{ width: '12%' }}>Managing Editor</td>
                                                        <td>
                                                            {(repFileData.me_file != null) ?
                                                                <a href={window.storage + "downloadReply/" + repFileData.me_file} download={repFileData.me_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                    <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                </a> : <a></a>}<br />
                                                            <b>Comments:</b> {repFileData.me_comments}
                                                        </td>
                                                    </tr>
                                                </> :
                                                <></>}
                                            {(repFileData.ret_se_comments != null) ?
                                                <>
                                                    <tr>
                                                        <td style={{ width: '12%' }}>Section Editor</td>
                                                        <td>
                                                            {(repFileData.ret_se_file != null) ?
                                                                <a href={window.storage + "downloadReply/" + repFileData.ret_se_file} download={repFileData.ret_se_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                    <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                </a> : <a></a>}<br />
                                                            <b>Comments:</b> {repFileData.ret_se_comments}
                                                        </td>
                                                    </tr>
                                                </> :
                                                <></>}
                                            {(repFileData.edi_comments != null) ?
                                                <>
                                                    <tr>
                                                        <td style={{ width: '12%' }}>Editorial Office</td>
                                                        <td>
                                                            {(repFileData.edi_file != null) ?
                                                                <a href={window.storage + "downloadReply/" + repFileData.edi_file} download={repFileData.edi_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                    <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                </a> : <a></a>}<br />
                                                            <b>Comments:</b> {repFileData.edi_comments}
                                                        </td>
                                                    </tr>
                                                </> :
                                                <></>}
                                        </tbody>
                                    </Table>
                                    <hr />
                                    {Object.keys(repManuData).length > 0 ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <center>
                                                        <h5>Reviewers Report</h5>
                                                    </center>
                                                </div>
                                            </div>
                                        </> : <></>}
                                    {Object.keys(repManuData).length > 0 ?
                                        <>
                                            <Table bordered size="sm">
                                                <thead className="text-center">
                                                    <tr>
                                                        <th style={{ width: '9%' }}>Reviewed By</th>
                                                        <th>Decision</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {repManuData.map((e, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        {e.Reviewer_Name}<br />
                                                                        {e.created_at ? e.created_at.substr(0, 10) : <></>}
                                                                    </td>
                                                                    <td>
                                                                        {(e.assigned_manu_status == 0) ?
                                                                            <><i className="text-danger">Reviewer Rejected Manuscript</i></> :
                                                                            (e.assigned_manu_status == 1) ?
                                                                                <><i className="text-warning">Pending for Acceptance</i></> :
                                                                                (e.assigned_manu_status == 2) ?
                                                                                    <><i className="text-primary">Decision Pending</i></> :
                                                                                    <>
                                                                                        {(e.id) ?
                                                                                            <>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-6"><b>Do you want get credit for reviewing this manuscript?</b> ({e.paid})</div>
                                                                                                    <div className="col-sm-6"><b>Subjects and contents are suitable for publications in the VSOHJ?</b> ({e.q1})</div>
                                                                                                    <div className="col-sm-6"><b>Novelty of results is appropriate?</b> ({e.q2})</div>
                                                                                                    <div className="col-sm-6"><b>Conclusions are well proven?</b> ({e.q3})</div>
                                                                                                    <div className="col-sm-6"><b>Title is suitable?</b> ({e.q4})</div>
                                                                                                    <div className="col-sm-6"><b>Number and quality of references are appropriate?</b> ({e.q5})</div>
                                                                                                    <div className="col-sm-6"><b>The linguistic quality of paper is good?</b> ({e.q6})</div>
                                                                                                    <div className="col-sm-6"><b>Reference is provided where data are reported?</b> ({e.q7})</div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6">
                                                                                                        <b>Recomendations:  </b>
                                                                                                        {
                                                                                                            (e.r === 'r1') ?
                                                                                                                <>
                                                                                                                    <span>Accept in Present Form</span>
                                                                                                                </> :
                                                                                                                (e.r === 'r2') ?
                                                                                                                    <>
                                                                                                                        <span>Consider After Minor Revision</span>
                                                                                                                    </> :
                                                                                                                    (e.r === 'r3') ?
                                                                                                                        <>
                                                                                                                            <span>Reassess After Major Revision</span>
                                                                                                                        </> :
                                                                                                                        (e.r === 'r4') ?
                                                                                                                            <>
                                                                                                                                <span>Reject</span>
                                                                                                                            </> :
                                                                                                                            <></>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col-md-6">
                                                                                                        {(e.reply_file_path != null) ?
                                                                                                            <a href={window.storage + "downloadRevReply/" + e.reply_file_path} download={e.reply_file_path} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reviewer Reply File
                                                                                                            </a> : <a></a>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-12">
                                                                                                        <b>Confidential Comments to Executive Editor: </b>{e.ex_co}
                                                                                                    </div>
                                                                                                    <div className="col-sm-12">
                                                                                                        <b>Comments to the Author: </b>{e.aut_co}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> :
                                                                                            <><i>Not Replied Yet</i></>}
                                                                                    </>}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}

                                                </tbody>
                                            </Table>
                                        </> :
                                        <><tr><td>No Data</td></tr></>}
                                </>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={rejShow} size="lg" onHide={rejModalClose}>
                <Modal.Header>
                    <Modal.Title>Reject Manuscript</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label htmlFor="formFile" className="form-label">Reply File</label>
                                    {fileLoader ?
                                        <>
                                            <p><i style={{ color: 'red' }}>Uploading...</i></p>
                                        </> :
                                        <>
                                            {rejManuFilePath ?
                                                <>
                                                    <p>
                                                        <Badge bg="success" style={{ color: 'white' }}>Reply File Uploaded</Badge> <i onClick={clearReplyFile} className="fas fa-times"></i>
                                                    </p>
                                                </> :
                                                <>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        onChange={e => handleReplyFile(e.target.files)}
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='col-md-12'>
                                    <label htmlFor="formFile" className="form-label">Comments</label>
                                    <textarea value={rejManuComments} onChange={(e) => setRejManuComments(e.target.value)} className="form-control" rows={6} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={rejToAuthor} disabled={!rejManuComments || fileLoader}>
                        {rejLoader ? 'Rejecting! Please wait' : 'Reject'}
                    </Button>
                    <Button variant="secondary" onClick={rejModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {(manuListLoader) ?
                <><i>Fecthing Record Please Wait.....</i></> :
                <>
                    {Object.keys(manuscript).length > 0 ?
                        <>
                            <Table striped bordered size="sm" className="dashboard-table">
                                <thead>
                                    <tr>
                                        <th>Files</th>
                                        <th>Paper No.</th>
                                        <th>Manuscript Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manuscript.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <button className="btn"
                                                            onClick={
                                                                () => {
                                                                    setOpen((e) => e.map((o, i) => {
                                                                        if (i === index) return !o;
                                                                        return o;
                                                                    })
                                                                    )
                                                                }}
                                                        >
                                                            <i className='fas fa-angle-down'></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {(e.manu_year != 0 && e.manu_pno != 0) ? <>VSOHJ-{e.manu_year}-{e.manu_pno}</> : <></>}
                                                        <br />
                                                        {e.assign_date ? e.assign_date.substr(0, 10) : <></>}
                                                    </td>
                                                    <td>{e.manu_title}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>

                                                        <Collapse in={open[index]}>
                                                            <div className="w-100 ">
                                                                <Table size="sm">
                                                                    {e.manu_files.map(f => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{f.created_at.substr(0, 10)}</td>
                                                                                    {(f.manu_file != null) ?
                                                                                        <td>
                                                                                            <a href={window.storage + "downloadManuscript/" + f.manu_file} download={f.manu_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Manu File
                                                                                            </a>
                                                                                        </td>
                                                                                        : <></>}


                                                                                    {(f.cover_file != null) ?
                                                                                        <td>
                                                                                            <a href={window.storage + "downloadCover/" + f.cover_file} download={f.cover_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Cover File
                                                                                            </a>
                                                                                        </td> :
                                                                                        <></>}


                                                                                    {(f.suplementry_file != null) ?
                                                                                        <td>
                                                                                            <a href={window.storage + "downloadSuplementry/" + f.suplementry_file} download={f.suplementry_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Suplementry File
                                                                                            </a>
                                                                                        </td> :
                                                                                        <></>}

                                                                                    <td>
                                                                                        <button className="btn text-success" onClick={() => repModalShow(e.manu_id, f.manu_file_id, e.manu_title, e.manu_year, e.manu_pno)}>
                                                                                            View Report
                                                                                        </button>
                                                                                    </td>

                                                                                    {(f.file_status == 0) ?
                                                                                        <>
                                                                                            <td>
                                                                                                <button className="btn text-success" onClick={() => revModalShow(e.manu_id, e.manu_title, e.manu_year, e.manu_pno, f.manu_file_id)}>Send Rev</button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn text-success" onClick={() => retAuthModalShow(e.manu_id, f.manu_file_id, e.manu_title, e.manu_year, e.manu_pno, e.author_name, e.author_email)}>Ret. to Author</button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-success btn-sm" onClick={() => acceptManuscript(e.manu_id, f.manu_file_id, e.manu_title, e.manu_year, e.manu_pno, e.author_name, e.author_email)}>Accept</button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-danger btn-sm" onClick={() => rejModalShow(e.manu_id, f.manu_file_id, e.manu_title, e.manu_year, e.manu_pno, e.author_name, e.author_email)}>Reject</button>
                                                                                            </td>
                                                                                        </> :
                                                                                        <><td colSpan={4}><Badge bg="danger">Not Eligible to proceed</Badge></td></>
                                                                                    }
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </Table>
                                                            </div>
                                                        </Collapse>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}
                </>}
        </>
    )
}

export default InProManu