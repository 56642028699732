import React, { useState } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Manu from './memanu-comp/Manu'
import RetManu from './memanu-comp/RetManu'
import SeManu from './memanu-comp/SeManu'
import SeProManu from './memanu-comp/SeProManu'
import DmeProManu from './memanu-comp/DmeProManu'
import PubManu from './memanu-comp/PubManu'
import RejManu from './memanu-comp/RejManu'
import Nav from 'react-bootstrap/Nav'

const MEManuscript = () => {

    const [key, setKey] = useState('Manu')

    async function changeComponent(compName) {
        setKey(compName)
    }

    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Managing Editor Manuscripts</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Nav variant="tabs" defaultActiveKey="link-1">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-1" onClick={() => changeComponent('Manu')}>Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-2" onClick={() => changeComponent('RetManu')}>Ret Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-3" onClick={() => changeComponent('SeManu')}>SE Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-4" onClick={() => changeComponent('SeProManu')}>SE Pro Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-5" onClick={() => changeComponent('DmeProManu')}>DME Pro Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-6" onClick={() => changeComponent('PubManu')}>Pub Manu.</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-7" onClick={() => changeComponent('RejManu')}>Rej Manu.</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-md-12'>
                                                    {(key === 'Manu') ?
                                                        <>
                                                            <Manu />
                                                        </> :
                                                        (key === 'RetManu') ?
                                                            <>
                                                                <RetManu />
                                                            </> :
                                                            (key === 'SeManu') ?
                                                                <>
                                                                    <SeManu />
                                                                </> :
                                                                (key === 'SeProManu') ?
                                                                    <>
                                                                        <SeProManu />
                                                                    </> :
                                                                    (key === 'DmeProManu') ?
                                                                        <>
                                                                            <DmeProManu />
                                                                        </> :
                                                                        (key === 'PubManu') ?
                                                                            <>
                                                                                <PubManu />
                                                                            </> :
                                                                            (key === 'RejManu') ?
                                                                                <>
                                                                                    <RejManu />
                                                                                </> :
                                                                                <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default MEManuscript