import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'

const Issues = () => {

  const userId = window.sessionStorage.getItem("id")

  const [volumes, setVolumes] = useState([])
  const [issues, setIssues] = useState([])
  const [name, setName] = useState("")
  const [volume, setVolume] = useState("")
  const [id, setId] = useState("")
  const [savingLoader, setSavingLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState("")

  const getVolume = async () => {
    try {
      const res = await fetch(window.api + "getAllVolumeAdmin")
      const data = await res.json()
      setVolumes(data.volumeList)

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getVolume()
  }, []);

  const getIssue = async (vol) => {
    setVolume(vol)
    try {
      const res = await fetch(window.api + "getAllIssueAdmin/" + vol)
      const data = await res.json()
      setIssues(data.issueList)

    } catch (err) {
      console.log(err);
    }
  }

  function setEditIssue(id, vol, name) {
    setId(id)
    setVolume(vol)
    setName(name)
  }

  function unSetEditIssue() {
    setId("")
    setVolume("")
    setName("")
  }

  async function save() {
    setSavingLoader(true)
    const formData = new FormData()
    formData.append('id', id)
    formData.append('volume', volume)
    formData.append('name', name)
    formData.append('updated_by', userId)

    try {
      let res = await fetch(window.api + "saveIssue", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.already) {
        setSavingLoader(false)
        setSuccess(false)
        setSuccessText("")
        setError(true)
        setErrorText("Speciality already added")
      } else if (res.success) {
        setSavingLoader(false)
        setId("")
        setName("")
        getVolume()
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteIssue(id) {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to Delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => del(id)
        },
        {
          label: 'No',

        }
      ]
    })

  }

  async function del(id) {
    setLoader(true)
    const formData = new FormData()
    formData.append('id', id)

    try {
      let res = await fetch(window.api + "deleteIssue", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.success) {
        getVolume()
        setLoader(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
      <div className='row'>
        <div className='col-md-5'>
          {error ?
            <>
              <div class="alert alert-danger">
                {errorText}
              </div>
            </> :
            success ?
              <>
                <div class="alert alert-success">
                  {successText}
                </div>
              </> :
              <></>
          }
          <div className='form-group'>
            <label>Volume</label>
            <select className='form-control' value={volume} onChange={(e) => getIssue(e.target.value)}>
              <option value="">--Select Volume--</option>
              {Object.keys(volumes).length > 0 ? volumes.map(v => (
                <option value={v.id}>{v.name} ({v.Year})</option>
              )) : <></>}
            </select>
          </div>
          <div className='form-group'>
            <label>Issue Name</label>
            <input type='text' placeholder='e.g. Issue 1' className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className='form-group'>
            <button onClick={save} className='btn btn-success' disabled={!name || !volume}>{savingLoader ? <>Saving..</> : <>{id ? 'Update' : 'Save'} </>}</button>
            {id ?
              <>
                <button onClick={unSetEditIssue} className='btn btn-danger'>Cancel</button>
              </> :
              <></>}
          </div>
        </div>
        <div className='col-md-7'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Issue Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(issues).length > 0 ? issues.map(i => (
                (i.name === name && id == i.id) ?
                  <></> :
                  <tr>
                    <td>{i.name}</td>
                    <td>
                      <i className='far fa-edit edit-icon-i' onClick={() => setEditIssue(i.id, i.vol_id, i.name)}></i>
                      <i className='far fa-trash-alt delete-icon-i' onClick={() => deleteIssue(i.id)}></i>
                    </td>
                  </tr>

              )) : <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Issues