import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Badge from 'react-bootstrap/Badge'
import Loading from 'react-fullscreen-loading'

const Manuscripts = () => {

    const userId = window.sessionStorage.getItem("id")

    const [manuscript, setManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [fileLoader, setFileLoader] = useState(false)
    const [returnLoader, setReturnLoader] = useState(false)
    const [returnManuId, setReturnManuId] = useState("")
    const [returnManuFileId, setReturnManuFileId] = useState("")
    const [returnManuUserName, setReturnManuUserName] = useState("")
    const [returnManuUserEmail, setReturnManuUserEmail] = useState("")
    const [returnManuTitle, setReturnManuTitle] = useState("")
    const [returnManuFile, setReturnManuFile] = useState("")
    const [returnManuFilePath, setReturnManuFilePath] = useState("")
    const [returnManuComments, setReturnManuComments] = useState("")
    const [loader, setLoader] = useState(false)

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    const [returnShow, setReturnShow] = useState(false)
    const returnModalClose = () => {
        setReturnShow(false)
        setReturnManuFile("")
        setReturnManuFilePath("")
        setReturnManuId("")
        setReturnManuFileId("")
        setReturnManuComments("")
    }
    const returnModalShow = (manuId, manuUser, manuEmail, manuTitle, manuFileId) => {
        setReturnManuId(manuId)
        setReturnManuFileId(manuFileId)
        setReturnManuUserName(manuUser)
        setReturnManuUserEmail(manuEmail)
        setReturnManuTitle(manuTitle)
        const comments = "Dear " + manuUser + "! I am very much thankful to you for considering ‘Veterinary Sciences and One Health Journal’ (VSOHJ) to publish your scientific and professional achievements. However, I am sorry to inform you that your manuscript entitled ‘" + manuTitle + "’ does not follow the basic format, structure and/or similarity index required by VSOHJ mentioned in authors instructions. I am please to request you to resubmit your manuscript after fulfilling the basic criteria of the journal mentioned in authors’ instructions. Looking forward for your future submissions.Best Regards"
        setReturnManuComments(comments)
        setReturnShow(true)
    }

    async function manuscriptData() {
        let datares = await fetch(window.api + "getAllManuAdmin")
        datares = await datares.json()
        setManuscript(datares.manuscripts)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    const handleReplyFile = async (file) => {
        setReturnManuFile(file[0])
        setFileLoader(true)
        const formData = new FormData()
        formData.append('replyFile', file[0])
        try {
            let replyUploadData = await fetch(window.api + "uploadReply", {
                method: 'POST',
                body: formData,
            })

            replyUploadData = await replyUploadData.json()
            console.warn(replyUploadData)
            if (replyUploadData.path) {
                setFileLoader(false)
                setReturnManuFilePath(replyUploadData.path)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const clearReplyFile = () => {
        setReturnManuFile("")
        setReturnManuFilePath("")
    }

    async function upDownload(manuId) {
        let downRes = await fetch(window.api + "upDownload/" + manuId)
        downRes = await downRes.json()
        if (downRes.success) {
            manuscriptData()
        }
    }

    async function sendToMe(manuId,manuUserName,manuUserEmail,manuTitle) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Send(manuId,manuUserName,manuUserEmail,manuTitle)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async function cancleToMe(manuId,manuUserName,manuUserEmail,manuTitle) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Cancle(manuId,manuUserName,manuUserEmail,manuTitle)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async function Send(manuId,manuUserName,manuUserEmail,manuTitle) {
        setLoader(true)
        const formData = new FormData()
        formData.append('sendme_manu_id', manuId)
        formData.append('sendme_manu_user_name', manuUserName)
        formData.append('sendme_manu_user_email', manuUserEmail)
        formData.append('sendme_manu_title', manuTitle)
        formData.append('updated_by', userId)
        try {
            let sendRes = await fetch(window.api + "sendToMe", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                setLoader(false)
                manuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function Cancle(manuId,manuUserName,manuUserEmail,manuTitle) {
        setLoader(true)
        const formData = new FormData()
        formData.append('cancle_manu_id', manuId)
        formData.append('cancle_manu_user_name', manuUserName)
        formData.append('cancle_manu_user_email', manuUserEmail)
        formData.append('cancle_manu_title', manuTitle)
        formData.append('updated_by', userId)
        try {
            let cancleRes = await fetch(window.api + "cancleToMe", {
                method: 'POST',
                body: formData,
            })

            cancleRes = await cancleRes.json()
            if (cancleRes.success) {
                setLoader(false)
                manuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function returnToAuthor() {
        setReturnLoader(true)
        const formData = new FormData()
        formData.append('ret_manu_id', returnManuId)
        formData.append('ret_manu_user_name', returnManuUserName)
        formData.append('ret_manu_user_email', returnManuUserEmail)
        formData.append('ret_manu_title', returnManuTitle)
        formData.append('ret_manu_file_id', returnManuFileId)
        formData.append('ret_manu_file_path', returnManuFilePath)
        formData.append('ret_manu_comments', returnManuComments)
        formData.append('updated_by', userId)
        try {
            let returnData = await fetch(window.api + "returnToAuthor", {
                method: 'POST',
                body: formData,
            })

            returnData = await returnData.json()
            if (returnData.success) {
                setReturnLoader(false)
                manuscriptData()
                returnModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <Modal show={authorShow} onHide={authorModalClose}>
                        <Modal.Header>
                            <Modal.Title>Author Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <table className='table table-striped'>
                                        <thead>
                                            <th>Name</th>
                                            <th>Affiliation</th>
                                        </thead>
                                        {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                            <tbody>
                                                <tr>
                                                    <td>{ad.author_name}</td>
                                                    <td>{ad.author_affiliation}</td>
                                                </tr>
                                            </tbody>
                                        )) : <></>}
                                    </table>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={authorModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={returnShow} size="xl" onHide={returnModalClose}>
                        <Modal.Header>
                            <Modal.Title>Return Manuscript</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label htmlFor="formFile" className="form-label">Reply File</label>
                                            {fileLoader ?
                                                <>
                                                    <p><i style={{ color: 'red' }}>Uploading...</i></p>
                                                </> :
                                                <>
                                                    {returnManuFilePath ?
                                                        <>
                                                            <p>
                                                                <Badge bg="success" style={{ color: 'white' }}>Manuscript File Uploaded</Badge> <i onClick={clearReplyFile} className="fas fa-times"></i>
                                                            </p>
                                                        </> :
                                                        <>
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                onChange={e => handleReplyFile(e.target.files)}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className='col-md-12'>
                                            <label htmlFor="formFile" className="form-label">Comments</label>
                                            <textarea value={returnManuComments} onChange={(e) => setReturnManuComments(e.target.value)} className="form-control" style={{ height: '100px' }}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={returnToAuthor} disabled={!returnManuComments || fileLoader}>
                                {returnLoader ? 'Returning! Please wait' : 'Return'}
                            </Button>
                            <Button variant="secondary" onClick={returnModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="main-content">
                        {loader? <><Loading loading  loaderColor="#3498db" /></>:<></>}
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>All Manuscripts</h4>
                                            {/* <div className="card-header-form">
                                                <form>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search" />
                                                        <div className="input-group-btn">
                                                            <button className="btn btn-primary"><i className="fas fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '120px' }}>Sr#</th>
                                                            <th>Title / Author</th>
                                                            <th style={{ width: '125px' }}>Status</th>
                                                            <th>File</th>
                                                            <th>M.E</th>
                                                            <th>Aut</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(manuscript).length > 0 ? manuscript.map(m => (
                                                            <tr key={m}>
                                                                <td>
                                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{m.manu_year + "-VSOHJ-" + m.manu_pno}</>}<br />
                                                                    {m.manu_date.substr(0, 10)}
                                                                </td>
                                                                <td>
                                                                    {m.manu_title}<br />
                                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors)}><i className='fas fa-user'></i>
                                                                        {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                                            <> {a.author_name},</>
                                                                        )) : <></>}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (m.manu_status == 0) ?
                                                                            <>Rejected</> :
                                                                            (m.manu_status == 1) ?
                                                                                <>Editorial Process</> :
                                                                                (m.manu_status == 2 || m.manu_status == 5) ?
                                                                                    <>Sent to M.E<br /><span className="icon-hand" onClick={() => cancleToMe(m.manu_id,m.manu_user_name,m.manu_user_email,m.manu_title)} style={{ color: "red" }}>Cancle</span></> :
                                                                                    (m.manu_status == 3) ?
                                                                                        <>Rejected by M.E</> :
                                                                                        (m.manu_status == 4) ?
                                                                                        <>Sent to S.E</>:
                                                                                        <></>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (m.manu_down_status == 0) ?
                                                                            <>
                                                                                <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                                    <i onClick={() => upDownload(m.manu_id)} style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                                                </a>
                                                                            </> :
                                                                            <>
                                                                                <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                                    <i onClick={() => upDownload(m.manu_id)} style={{ color: '#648A4D', fontSize: '20px' }} className="fas fa-file-download"></i>
                                                                                </a>
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(m.manu_status == 1) ?
                                                                        <>
                                                                            <span className="icon-hand" onClick={() => sendToMe(m.manu_id,m.manu_user_name,m.manu_user_email,m.manu_title)}>Send</span>
                                                                        </> :
                                                                        <></>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(m.manu_status == 1) ?
                                                                        <>
                                                                            <span className="icon-hand" onClick={() => returnModalShow(m.manu_id,m.manu_user_name,m.manu_user_email,m.manu_title,m.manu_file_id)}>Return</span>
                                                                        </> :
                                                                        <></>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )) :
                                                            <></>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Manuscripts